import pdfMake from 'pdfmake/build/pdfmake'
import type { TDocumentDefinitions } from 'pdfmake/interfaces'
import vfs from 'pdfmake/build/vfs_fonts.js'
import print from 'print-js'

window.pdfMake.vfs = vfs.pdfMake.vfs

export async function imprimirComprovanteDeCancelamentoDoPagamento(comprovante: string): Promise<void> {
	const comprovanteReplicado: string[] = new Array(2).fill(comprovante)
	const pdf: string = await gerarDocumentoParaImpressaoDoComprovante(comprovanteReplicado)
	return new Promise((resolve, reject) => {
		print({
			printable: pdf,
			type: 'pdf',
			base64: true,
			onError: reject,
			onLoadingEnd: resolve,
		})
	})
}

async function gerarDocumentoParaImpressaoDoComprovante(params: string[]): Promise<string> {
	const content: any[] = params.map((comprovante, index) => {
		if (index === 0) {
			comprovante = comprovante
				.replace(/NRO LOGICO:.*?\n/g, '')
				.replace(/LABEL:.*?\n/g, '')
				.replace(/AID:.*?\n/g, '')
				.replace(/ARQC:.*?\n/g, '');
		}

		return { text: comprovante, pageBreak: index < params.length - 1 ? 'after' : undefined };
	});

	const docDefinition: TDocumentDefinitions = {
		pageSize: {
			width: 1000,
			height: 1000,
		},
		pageOrientation: 'portrait',
		pageMargins: [3, 5, 5, 5],
		content: [
			{ text: content[0].text},
			{ text: '---------------------------------------------------------------------------------------------------------', margin: [0, 10, 0, 0 ] } ,
			{ text: content[1].text, margin: [0, 20, 0, 0 ] },
		],
	};

	return new Promise((resolve) =>
		pdfMake.createPdf(docDefinition).getBase64((result) => resolve(result)),
	);
}