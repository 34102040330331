




































































































































import { TicketTiflux } from '@/models';
import type { VendaComReferencias } from '@/models';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { EditarUmaVendaUseCase, FindModalidadeDeVendaUseCase } from '@/usecases';
import { FindTicketTifluxUseCase } from '@/usecases/tiflux/FindTicketTifluxUseCase';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

@Component
export default class DialogoDeDetalhesDoTicket extends Vue {
	@Ref() form!: HTMLFormElement
	@Prop() venda!: VendaComReferencias	
	@Prop() lojaId!: string	
	
	mostra = false	
	numeroTicket: string | null = null
	numeroDoTicket = this.venda.numeroTicket	
	
	editarUmaVendaUseCase = new EditarUmaVendaUseCase()
	findModalidadeDeVendaUseCase = new FindModalidadeDeVendaUseCase()
	findTicketTifluxUseCase = new FindTicketTifluxUseCase()
	ticketInfo: TicketTiflux | null = null
	isLoading = false
	serverError: any = null
	
	onDialogOpen(val: boolean) {
		if (val) {
			this.fetchTicketInfo();
		}
	}
	
	geInitial(name: string) {		
		if (!name || typeof name !== 'string') {
			return ''
		}

		return name?.trim().charAt(0).toUpperCase()		 
	}

	getStatus(status: string) {
		const estado = status === "first_status_name" ? 'Aberto' : 'Faturado'

		return estado
	}

	getPrioridade(prioridade: string) {
		switch (prioridade) {
			case 'high_priority_name':
				return 'Alta'
			case 'low_priority_name':
				return 'Baixa'
			default:
				return prioridade
		}
	}	

	reformataDescricao(text: string) {
		text = text.replace(/<[^>]*>/g, ' ')
		text = text.replace(/&nbsp;/g, ' ');
		text = text.replace(/&amp;/g, '&')

		return text;
	}

	mostrar() {
		this.mostra = true;		
	}
  
	async fetchTicketInfo() {
		if (!this.venda.numeroTicket) return;

		try {
			this.isLoading = true;
			this.findTicketTifluxUseCase = new FindTicketTifluxUseCase();

			const ticketInfo = await this.findTicketTifluxUseCase.fetchTicketInfo(this.venda.numeroTicket);

			if (!ticketInfo) {
				AlertModule.setError('Ticket não encontrado');
				return;
			}

			this.ticketInfo = ticketInfo;
		} catch (error: any) {			
			AlertModule.setError('Ticket não encontrado!');
		} finally {
			this.isLoading = false;
		}
	}

	esconder() {
		this.mostra = false
	}

	dateTimeToPtBrFormat(dateTime: string): string {
		const date = new Date(dateTime);
		return date.toLocaleDateString('pt-BR', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
		});
	}
}
